import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  FormControlLabel,
  Radio,
  Checkbox,
  Collapse,
  Chip,
  IconButton,
  styled,
} from "@mui/material";
import axios from "axios";

import SchoolIcon from "@mui/icons-material/School";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAuth } from "@clerk/clerk-react";

 //const API_BASE_URL = "http://localhost:2004/api";
const API_BASE_URL = "https://neetlybackend.vercel.app/api";

// Styled Card for MCQs
const StyledCardForMCQ = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: "12px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
  padding: theme.spacing(3),
  maxWidth: "100%",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.01)",
  },
}));

const QuestionAttempt = ({
    questionType,
    questions,
    onNext,
    onPrevious,
    currentPage,
    totalPages,
    onBack,
  }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Handles local question navigation
    const [answers, setAnswers] = useState({});
    const [submittedQuestions, setSubmittedQuestions] = useState({}); // Tracks submitted questions
  
    const currentQuestion = questions?.[currentQuestionIndex];
    const { getToken } = useAuth();

    if (!currentQuestion) {
      return (
        <Box sx={{ padding: "20px", textAlign: "center" }}>
          <Typography variant="h6" color="error">
            No questions available for this topic and type.
          </Typography>
          <Button variant="outlined" onClick={onBack}>
            Back to Question Types
          </Button>
        </Box>
      );
    }
  
    const handleAnswerChange = (option, isMultiple) => {
      setAnswers((prev) => {
        const prevAnswers = prev[currentQuestion._id] || [];
        if (isMultiple) {
          const newAnswers = prevAnswers.includes(option)
            ? prevAnswers.filter((o) => o !== option)
            : [...prevAnswers, option];
          return { ...prev, [currentQuestion._id]: newAnswers };
        } else {
          return { ...prev, [currentQuestion._id]: [option] };
        }
      });
    };
  
    const handleSubmit = async () => {
      try {
        const token = await getToken();
    const response = await axios.post(
        `${API_BASE_URL}/mcq/save-attempt`, 
        {
            questionId: currentQuestion._id,
            topicId: currentQuestion.topicId, // Assuming `topicId` is part of the question object
            subjectId: currentQuestion.subjectId, // Assuming `subjectId` is part of the question object
            selectedAnswers: answers[currentQuestion._id] || [],
            isCorrect,
            reattempt: false, // Adjust if reattempt handling is needed
        }, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    console.log(response.data);
  
        if (response.status === 201) {
          console.log("Attempt saved successfully");
          setSubmittedQuestions((prev) => ({
            ...prev,
            [currentQuestion._id]: true,
          }));
        }
      } catch (error) {
        console.error("Error saving attempt:", error.message);
      }
    };
  
    const handleNextQuestion = () => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prev) => prev + 1);
      } else if (currentPage < totalPages - 1) {
        onNext();
        setCurrentQuestionIndex(0);
      }
    };
  
    const handlePreviousQuestion = () => {
      if (currentQuestionIndex > 0) {
        setCurrentQuestionIndex((prev) => prev - 1);
      } else if (currentPage > 0) {
        onPrevious();
        setCurrentQuestionIndex(questions.length - 1);
      }
    };
  
    const isCorrect =
      JSON.stringify(currentQuestion?.correctAnswers?.sort() || []) ===
      JSON.stringify((answers[currentQuestion?._id] || []).sort());
  
    const isSubmitted = submittedQuestions[currentQuestion?._id];
  
    return (
      <Box sx={{ width: "100%", minHeight: "100vh", padding: "20px", backgroundColor: "#f5f5f5" }}>
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <Avatar sx={{ bgcolor: "#3498db", mr: 2 }}>
            <SchoolIcon />
          </Avatar>
          <Typography variant="h5" sx={{ color: "#3498db" }}>
            {questionType}
          </Typography>
        </Box>
  
        {/* Question Box */}
        <StyledCardForMCQ>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="subtitle1" sx={{ color: "#3498db" }}>
              Question {currentQuestion?.questionNumber || currentQuestionIndex + 1}
            </Typography>
            <Chip
              label={currentQuestion?.difficulty || "N/A"}
              color="primary"
              variant="outlined"
            />
          </Box>
          <Typography variant="body1" sx={{ color: "#34495e", mb: 2 }}>
            {currentQuestion?.text || "Question not available"}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
            {currentQuestion?.options?.map((option) => (
              <FormControlLabel
                key={option._id}
                control={
                  currentQuestion?.questionType === "multiple" ? (
                    <Checkbox
                      checked={(answers[currentQuestion?._id] || []).includes(option._id)}
                      onChange={() => handleAnswerChange(option._id, true)}
                      disabled={isSubmitted}
                    />
                  ) : (
                    <Radio
                      checked={(answers[currentQuestion?._id] || []).includes(option._id)}
                      onChange={() => handleAnswerChange(option._id, false)}
                      disabled={isSubmitted}
                    />
                  )
                }
                label={<Typography sx={{ color: "#2c3e50" }}>{option.text}</Typography>}
              />
            ))}
          </Box>
          <Collapse in={isSubmitted}>
            <Box sx={{ p: 2, bgcolor: "#f5f5f5", borderRadius: "8px" }}>
              <Typography variant="body2" color={isCorrect ? "success.main" : "error.main"}>
                {isCorrect ? "Correct!" : "Incorrect"}
              </Typography>
              <Typography variant="body2">
                Correct Answer:{" "}
                {currentQuestion?.correctAnswers?.map((answer) => (
                  <Chip key={answer} label={answer} color="success" size="small" sx={{ mx: 0.5 }} />
                ))}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {currentQuestion?.explanation || "No explanation available."}
              </Typography>
            </Box>
          </Collapse>
        </StyledCardForMCQ>
  
        {/* Navigation Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            maxWidth: "600px",
            margin: "20px auto",
          }}
        >
          <IconButton
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 0 && currentPage === 0}
            sx={{ bgcolor: "#3498db", color: "#fff", "&:hover": { bgcolor: "#2980b9" } }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          {!isSubmitted ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3498db",
                "&:hover": { backgroundColor: "#2980b9" },
              }}
              onClick={handleSubmit}
            >
              Submit Answer
            </Button>
          ) : (
            <IconButton
              onClick={handleNextQuestion}
              disabled={currentQuestionIndex === questions.length - 1 && currentPage === totalPages - 1}
              sx={{ bgcolor: "#3498db", color: "#fff", "&:hover": { bgcolor: "#2980b9" } }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Box>
  
        {/* Back to Question Types Button */}
        <Button variant="outlined" sx={{ display: "block", margin: "20px auto" }} onClick={onBack}>
          Back to Question Types
        </Button>
      </Box>
    );
  };
  
  export default QuestionAttempt;
  
  
