import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Card, CardContent, Button, Chip } from "@mui/material";

const ResultComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { testDetails, answers } = location.state || {};

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Test Results
      </Typography>
      {testDetails.questions.map((question, index) => (
        <Card key={question._id} sx={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography variant="h6">{`Q${index + 1}: ${question.text}`}</Typography>
            <Typography variant="body2">
              Your Answer:{" "}
              {question.options.find((o) => o._id === answers[question._id])?.text || "Not Answered"}
            </Typography>
            <Typography variant="body2">
              Correct Answer:{" "}
              {question.options.find((o) => o._id === question.correctAnswer)?.text}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Explanation: {question.explanation || "No explanation available."}
            </Typography>
          </CardContent>
        </Card>
      ))}
      <Button variant="contained" onClick={() => navigate("/")}>
        Back to Home
      </Button>
    </Box>
  );
};

export default ResultComponent;
