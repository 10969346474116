import React from 'react';
import { Box, Typography, Avatar, Chip, Collapse, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';

const StyledCard = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  width: '100%',
  border: '1px solid #ddd',
  backgroundColor: '#ffffff',
}));

const OptionChip = styled(Chip)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  width: '100%',
  justifyContent: 'flex-start',
  fontSize: '16px',
  borderRadius: '8px',
  padding: theme.spacing(1),
  textAlign: 'left',
}));

const ViewMCQSet = ({ set }) => {
  return (
    <StyledCard key={set.id} id={`mcq-set-${set.id}`} sx={{ width: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Avatar sx={{ bgcolor: '#3498db', mr: 2 }}>
            <SchoolIcon />
          </Avatar>
          <Typography variant="h6" sx={{ color: '#3498db', fontWeight: 'bold' }}>
            MCQs on {set.topic}
          </Typography>
        </Box>

        {set.questions.map((q) => {
          const isCorrect =
            q.correctAnswers &&
            q.userAnswer &&
            q.correctAnswers.every((ans) => q.userAnswer.includes(ans)) &&
            q.correctAnswers.length === q.userAnswer.length;

          return (
            <Box
              key={q.id}
              sx={{
                mb: 3,
                p: 2,
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                border: '1px solid #eee',
              }}
            >
              <Grid container spacing={2}>
                {/* Question and Explanation */}
                <Grid item xs={7}>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: '#3498db', fontWeight: 'bold', mb: 1 }}
                  >
                    {q.text}
                  </Typography>
                  <Collapse in={true}>
                    <Box sx={{ mt: 2, p: 2, backgroundColor: '#fdfdfd', borderRadius: '8px' }}>
                      <Typography
                        variant="body2"
                        color={isCorrect ? 'success.main' : 'error.main'}
                        sx={{ fontWeight: 'bold' }}
                      >
                        {isCorrect ? 'Correct!' : 'Incorrect'}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        <strong>Correct Answer:</strong> {q.correctAnswers ? q.correctAnswers.join(', ') : 'N/A'}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1, color: '#555' }}>
                        {q.explanation || 'No explanation available.'}
                      </Typography>
                    </Box>
                  </Collapse>
                </Grid>

                {/* Options */}
                <Grid item xs={5}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {q.options.map((option) => (
                      <OptionChip
                        key={option.id}
                        label={option.text}
                        sx={{
                          backgroundColor:
                            q.correctAnswers && q.correctAnswers.includes(option.id)
                              ? '#d4edda' // Green for correct answers
                              : q.userAnswer && q.userAnswer.includes(option.id)
                              ? '#f8d7da' // Red for incorrect answers
                              : '#e9ecef', // Neutral for unselected options
                          color: '#34495e',
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </CardContent>
    </StyledCard>
  );
};

export default ViewMCQSet;
