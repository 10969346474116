// import React from "react";
// import "./App.css";
// import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";
// import { ClerkProvider, SignedIn, SignedOut, useAuth } from "@clerk/clerk-react";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import HomePage from "./containers/HomePage";
// import SignInPage from "./routes/signInPage/signinPage.js";
// import ChatPage from "./containers/ChatPage";
// import SignUpPage from "./routes/signUpPage/signUpPage";
// import DashboardPage from "./containers/DashboardPage/index.js";
// import Explore from "./containers/Explore/index.js";

// const queryClient = new QueryClient();
// const PUBLISHABLE_KEY = "pk_test_dG9waWNhbC10b21jYXQtNDIuY2xlcmsuYWNjb3VudHMuZGV2JA";

// if (!PUBLISHABLE_KEY) {
//   throw new Error("Missing Publishable Key");
// }

// // Protected route wrapper
// const ProtectedRoute = ({ children }) => {
//   const { isSignedIn, isLoaded } = useAuth();

//   if (!isLoaded) {
//     return <div>Loading...</div>;
//   }

//   if (!isSignedIn) {
//     return <Navigate to="/sign-in" replace />;
//   }

//   return children;
// };

// function AppRoutes() {
//   let routes = useRoutes([
//     { path: "/", element: <HomePage /> },
//     { path: "/sign-in/*", element: <SignInPage /> },
//     { path: "/sign-up/*", element: <SignUpPage /> },
//     {
//       path: "/dashboard",
//       element: <ProtectedRoute><DashboardPage /></ProtectedRoute>
//     },
//     {
//       path: "/dashboard/chats/:id",
//       element: <ProtectedRoute><ChatPage /></ProtectedRoute>
//     },
//     {
//       path: "/explore",
//       element: <ProtectedRoute><Explore /></ProtectedRoute>
//     },
//   ]);
//   return routes;
// }

// function App() {
//   return (
//     <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
//       <QueryClientProvider client={queryClient}>
//         <div className="App">
//           <Router>
//             <AppRoutes />
//           </Router>
//         </div>
//       </QueryClientProvider>
//     </ClerkProvider>
//   );
// }

// export default App;

import React from "react";
import "./App.css";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";
import { ClerkProvider, SignedIn, SignedOut, useAuth } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HomePage from "./containers/HomePage";
import SignInPage from "./routes/signInPage/signinPage.js";
import ChatPage from "./containers/ChatPage";
import SignUpPage from "./routes/signUpPage/signUpPage";
import DashboardPage from "./containers/DashboardPage/index.js";
import Explore from "./containers/Explore/index.js";
import SubjectAnalytics from "./components/subjectAnalytics/index.js";
import SubjectWiseList from "./components/chatWindow/SubjectWiseList.js";
import TestComponent from "./components/chatWindow/TestComponent.js";
import ResultComponent from "./components/chatWindow/ResultComponent.js";
import TestHandler from "./components/chatWindow/TestHandler.js";
import ViewTest from "./components/chatWindow/viewTest.js";
const queryClient = new QueryClient();
const PUBLISHABLE_KEY = "pk_test_aW50ZXJuYWwtcm9iaW4tMC5jbGVyay5hY2NvdW50cy5kZXYk";

if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key");
}

// Protected route wrapper
const ProtectedRoute = ({ children }) => {
    const { isSignedIn, isLoaded } = useAuth();

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (!isSignedIn) {
        return <Navigate to="/sign-in" replace />;
    }

    return children;
};

function AppRoutes() {
    let routes = useRoutes([
        { path: "/", element: <HomePage /> },
        { path: "/sign-in/*", element: <SignInPage /> },
        { path: "/sign-up/*", element: <SignUpPage /> },
        {
            path: "/dashboard",
            element: (
                <ProtectedRoute>
                    <DashboardPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/dashboard/chats/:id",
            element: (
                <ProtectedRoute>
                    <ChatPage />
                </ProtectedRoute>
            ),
        },
        {
            path: "/explore",
            element: (
                <ProtectedRoute>
                    <Explore />
                </ProtectedRoute>
            ),
        },
        {
            path: "/explore/:mcqId",
            element: (
                <ProtectedRoute>
                    <Explore />
                </ProtectedRoute>
            ),
        },

        {
            path: "/test/subject",
            element: (
                <ProtectedRoute>
                    <TestHandler />
                </ProtectedRoute>
            ),
        },
        {
            path: "/view-test/:id",
            element: (
                <ProtectedRoute>
                    <ViewTest />
                </ProtectedRoute>
            ),
        },
        {
            path: "/results",
            element: (
                <ProtectedRoute>
                    <ResultComponent />
                </ProtectedRoute>
            ),
        },
        //
        {
            path: "/explore/personlisation",
            element: (
                <ProtectedRoute>
                    <SubjectAnalytics />
                </ProtectedRoute>
            ),
        },
        {
            path: "/subjects/mcqs",
            element: (
                <ProtectedRoute>
                    <SubjectWiseList />
                </ProtectedRoute>
            ),
        },
    ]);
    return routes;
}

function App() {
    return (
        <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
            <QueryClientProvider client={queryClient}>
                <div className="App">
                    <Router>
                        <AppRoutes />
                    </Router>
                </div>
            </QueryClientProvider>
        </ClerkProvider>
    );
}

export default App;
