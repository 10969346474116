// // import React, { useEffect, useState } from 'react';
// // import { useParams } from 'react-router-dom';
// // import ViewMCQSet from './ViewMCQSet'; // Import the updated MCQSet for view mode
// // import axios from 'axios';

// // const ViewTest = () => {
// //   const { attemptId } = useParams();
// //   const [testResults, setTestResults] = useState(null);

// //   useEffect(() => {
// //     const fetchTestResults = async () => {
// //       try {
// //         const response = await axios.get(`/api/test/attempt/${attemptId}`);
// //         setTestResults(response.data);
// //       } catch (error) {
// //         console.error('Error fetching test results:', error.message);
// //       }
// //     };

// //     fetchTestResults();
// //   }, [attemptId]);

// //   if (!testResults) return <div>Loading...</div>;

// //   return (
// //     <div>
// //       <h3 style={{ marginBottom: '20px' }}>Test Results</h3>
// //       <ViewMCQSet
// //         set={{
// //           id: testResults.testId,
// //           topic: testResults.topic || 'General Topic', // Use topic if available
// //           questions: testResults.questions,
// //           answers: testResults.questions.reduce((acc, question) => {
// //             acc[question.id] = question.userAnswer;
// //             return acc;
// //           }, {}),
// //           submitted: true, // Indicates that this is a submitted test
// //         }}
// //       />
// //     </div>
// //   );
// // };

// // export default ViewTest;
// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import ViewMCQSet from './ViewMCQSet'; // Import the updated MCQSet for view mode
// import axios from 'axios'; // Import axios for API calls

// const ViewTest = ({subjectId}) => {
//   //const { attemptId } = useParams();

//   const [testResults, setTestResults] = useState(null);

//   useEffect(() => {
//     const fetchTestResults = async () => {
//       try {
//         const response = await axios.get(`http://localhost:2004/api/test/67558bd4b82b95fc6d3aa682/submitDetais`);
//         setTestResults(response.data);
//       } catch (error) {
//         console.error('Error fetching test results:', error.message);
//       }
//     };

//     fetchTestResults();
//   }, [subjectId]);

//   if (!testResults) return <div>Loading...</div>;

//   return (
//     <div>
//       <h3 style={{ marginBottom: '20px' }}>Test Results</h3>
//       <ViewMCQSet
//         set={{
//           id: testResults.testId,
//           topic: testResults.metadata?.topicsCovered.join(', ') || 'General Topic',
//           questions: testResults.questions.map((q) => ({
//             id: q.questionId,
//             text: q.text,
//             options: q.options.map((opt) => opt.text),
//             correct: q.correctAnswers,
//             userAnswer: q.userAnswer,
//             explanation: q.isCorrect
//               ? 'Correct Answer!'
//               : `Explanation: ${q.options.find((opt) => opt.id === q.correctAnswers[0])?.text || ''}`,
//           })),
//           answers: testResults.questions.reduce((acc, question) => {
//             acc[question.questionId] = question.userAnswer;
//             return acc;
//           }, {}),
//           submitted: true, // Indicates that this is a submitted test
//         }}
//       />
//     </div>
//   );
// };

// export default ViewTest;

import React, { useEffect, useState } from 'react';
import ViewMCQSet from './ViewMCQSet'; // Import the updated MCQSet for view mode
import axios from 'axios'; // Import axios for API calls
const API_BASE_URL = "https://neetlybackend.vercel.app/api";

const ViewTest = ({ subjectId }) => {
  const [testResults, setTestResults] = useState(null);

  useEffect(() => {
    const fetchTestResults = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/test/67558bd4b82b95fc6d3aa682/submitDetais`);
        const formattedResults = {
          testId: response.data.testId,
          topic: response.data.metadata?.topicsCovered.join(', ') || 'General Topic',
          questions: response.data.questions.map((q) => ({
            id: q.questionId,
            text: q.text,
            options: q.options.map((opt) => ({
              id: opt.id,
              text: opt.text,
            })),
            correctAnswers: q.correctAnswers,
            userAnswer: q.userAnswer,
            explanation: q.isCorrect
              ? 'Correct Answer!'
              : `Explanation: ${q.explanation || 'No explanation provided.'}`,
            isCorrect: q.isCorrect,
          })),
          submittedAt: response.data.submittedAt,
        };
        setTestResults(formattedResults);
      } catch (error) {
        console.error('Error fetching test results:', error.message);
      }
    };

    fetchTestResults();
  }, [subjectId]);

  if (!testResults) return <div>Loading...</div>;

  return (
    <div>
      <h3 style={{ marginBottom: '20px' }}>Test Results</h3>
      <ViewMCQSet set={testResults} />
    </div>
  );
};

export default ViewTest;

