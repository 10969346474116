import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TestComponent from './TestComponent';
import ViewTest from './viewTest';
// import ViewTest from './ViewTest';

const API_BASE_URL = "https://neetlybackend.vercel.app/api";

const TestHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const subjectId = queryParams.get('subjectId'); // Extract subjectId from query parameters
    const [testStatus, setTestStatus] = useState(null);

  const getTestStatus = async (subjectId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/test/67558bd4b82b95fc6d3aa682/status`); // Replace with your backend's base URL
      return response.data;
    } catch (error) {
      console.error("Error fetching test status:", error.message);
      throw error; // Ensure errors are handled by the calling code
    }
  };

  useEffect(() => {
    const checkTestStatus = async () => {
      try {
        const statusData = await getTestStatus(subjectId); // Call the actual API
        setTestStatus(statusData);
      } catch (error) {
        console.error("Error fetching test status:", error.message);
        setTestStatus(null); // Handle API errors gracefully
      }
    };

    checkTestStatus();


  }, [subjectId]);

  if (!testStatus) return <div>Loading...</div>;

  if (testStatus.status === 'submitted') {
    navigate('/view-test/67558bd4b82b95fc6d3aa682', { state: "" });
}
  

  return <TestComponent subjectId={subjectId} />;
};

export default TestHandler;
