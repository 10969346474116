import {
    Box,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
  } from "@mui/material";
  import DashboardIcon from "@mui/icons-material/Dashboard";
  import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
  import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
  import { useNavigate } from "react-router-dom";
  
  const TopicSelectionCards = () => {
    const navigate = useNavigate();
  
    return (
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#f8f9fa",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#555", textAlign: "center", mb: 4 }}
        >
          Select an Option to Get Started
        </Typography>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => navigate('/dashboard')}
            >
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <DashboardIcon sx={{ fontSize: 40, color: "#3498db", mb: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: 500, color: "#444" }}>
                  Go to Case Study
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => navigate('/subjects/mcqs')}
            >
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <QuestionAnswerIcon sx={{ fontSize: 40, color: "#3498db", mb: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: 500, color: "#444" }}>
                  Go to Subject wise MCQ'S
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ mt: 6, textAlign: "center" }}>
          <Card
            sx={{
              borderRadius: 2,
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease-in-out",
              cursor: "pointer",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
              },
              maxWidth: 300,
              mx: "auto",
            }}
            onClick={() => navigate('/explore/personlisation')}
          >
            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <PersonalVideoIcon sx={{ fontSize: 40, color: "#9b59b6", mb: 2 }} />
              <Typography
                variant="h6"
                sx={{ fontWeight: 500, color: "#9b59b6", textTransform: "uppercase" }}
              >
                Go to Personalized Dashboard
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  };
  
  export default TopicSelectionCards;
  