// // import React from 'react';
// // import { Box, Typography, Avatar, Chip, Collapse, Button, IconButton } from '@mui/material';
// // import { styled } from '@mui/material/styles';
// // import SchoolIcon from '@mui/icons-material/School';
// // import { FormControlLabel, Radio } from '@mui/material';
// // import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// // import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// // const StyledCardForMCQ = styled(Box)(({ theme }) => ({
// //   marginBottom: theme.spacing(2),
// //   borderRadius: '12px',
// //   boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
// //   backgroundColor: '#ffffff',
// //   padding: theme.spacing(3),
// //   width: '100%',
// //   transition: 'transform 0.2s ease-in-out',
// //   '&:hover': {
// //     transform: 'scale(1.01)',
// //   },
// // }));

// // const TestMCQCard = ({
// //   question,
// //   questionIndex,
// //   totalQuestions,
// //   answers,
// //   handleAnswerChange,
// //   isSubmitted,
// //   isCorrect,
// //   timer,
// //   handlePreviousQuestion,
// //   handleNextQuestion,
// //   handleSubmitTest,
// //   currentQuestionIndex,
// // }) => {
// //   return (
// //     <Box sx={{ width: '100%', minHeight: '100vh', padding: '20px', backgroundColor: '#f5f5f5' }}>
// //       {/* Header */}
// //       <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
// //         <Avatar sx={{ bgcolor: '#3498db', mr: 2 }}>
// //           <SchoolIcon />
// //         </Avatar>
// //         <Typography variant="h5" sx={{ color: '#3498db' }}>
// //           Question {questionIndex + 1} of {totalQuestions}
// //         </Typography>
// //         <Typography variant="h6" sx={{ marginLeft: 'auto', color: timer <= 10 ? 'error.main' : 'text.primary' }}>
// //           Time Left: {timer}s
// //         </Typography>
// //       </Box>

// //       {/* Question Box */}
// //       <StyledCardForMCQ>
// //         <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
// //           <Typography variant="subtitle1" sx={{ color: '#3498db' }}>
// //             Question {questionIndex + 1}
// //           </Typography>
// //           <Chip label={question.difficulty || 'N/A'} color="primary" variant="outlined" />
// //         </Box>
// //         <Typography variant="body1" sx={{ color: '#34495e', mb: 2 }}>
// //           {question.text}
// //         </Typography>
// //         <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
// //           {question.options.map((option, index) => (
// //             <FormControlLabel
// //               key={index}
// //               control={
// //                 <Radio
// //                   checked={answers[question.id] === option}
// //                   onChange={() => handleAnswerChange(question.id, option)}
// //                   disabled={isSubmitted}
// //                 />
// //               }
// //               label={<Typography sx={{ color: '#2c3e50' }}>{option}</Typography>}
// //             />
// //           ))}
// //         </Box>
// //         <Collapse in={isSubmitted}>
// //           <Box sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: '8px' }}>
// //             <Typography variant="body2" color={isCorrect ? 'success.main' : 'error.main'}>
// //               {isCorrect ? 'Correct!' : 'Incorrect'}
// //             </Typography>
// //             <Typography variant="body2">
// //               Correct Answer: <Chip label={question.correct} color="success" size="small" sx={{ mx: 0.5 }} />
// //             </Typography>
// //             <Typography variant="body2" sx={{ mt: 1 }}>
// //               {question.explanation || 'No explanation available.'}
// //             </Typography>
// //           </Box>
// //         </Collapse>
// //       </StyledCardForMCQ>

// //       {/* Navigation Buttons */}
// //       <Box
// //         sx={{
// //           display: 'flex',
// //           justifyContent: 'space-between',
// //           alignItems: 'center',
// //           width: '100%',
// //           maxWidth: '600px',
// //           margin: '20px auto',
// //         }}
// //       >
// //         <IconButton
// //           onClick={handlePreviousQuestion}
// //           disabled={questionIndex === 0}
// //           sx={{ bgcolor: '#3498db', color: '#fff', '&:hover': { bgcolor: '#2980b9' } }}
// //         >
// //           <ArrowBackIosIcon />
// //         </IconButton>
// //         {questionIndex === totalQuestions - 1 ? (
// //           <Button
// //             variant="contained"
// //             sx={{
// //               backgroundColor: '#3498db',
// //               '&:hover': { backgroundColor: '#2980b9' },
// //             }}
// //             onClick={handleSubmitTest}
// //           >
// //             Submit Test
// //           </Button>
// //         ) : (
// //           <IconButton
// //             onClick={handleNextQuestion}
// //             sx={{ bgcolor: '#3498db', color: '#fff', '&:hover': { bgcolor: '#2980b9' } }}
// //           >
// //             <ArrowForwardIosIcon />
// //           </IconButton>
// //         )}
// //       </Box>
// //     </Box>
// //   );
// // };

// // export default TestMCQCard;
// // import React from 'react';
// // import { Box, Typography, RadioGroup, FormControlLabel, Radio, Button, Chip } from '@mui/material';

// // const TestMCQCard = ({
// //   question,
// //   questionIndex,
// //   totalQuestions,
// //   answers,
// //   handleAnswerChange,
// //   isSubmitted,
// //   isCorrect,
// //   timer,
// //   handlePreviousQuestion,
// //   handleNextQuestion,
// //   handleSubmitTest,
// // }) => {
// //   return (
// //     <Box
// //       sx={{
// //         border: '1px solid #ddd',
// //         borderRadius: '8px',
// //         padding: '16px',
// //         marginBottom: '16px',
// //         backgroundColor: isSubmitted ? (isCorrect ? '#d4edda' : '#f8d7da') : '#fff',
// //       }}
// //     >
// //       <Typography variant="h6">
// //         Question {questionIndex + 1} of {totalQuestions}
// //       </Typography>
// //       <Typography variant="body1" sx={{ marginBottom: '8px' }}>
// //         {question.text}
// //       </Typography>

// //       <RadioGroup
// //         value={answers[question.id] || ''}
// //         onChange={(e) => handleAnswerChange(question.id, e.target.value)}
// //         sx={{ marginBottom: '16px' }}
// //       >
// //         {question.options.map((option) => (
// //           <FormControlLabel
// //             key={option.id}
// //             value={option.id}
// //             control={<Radio />}
// //             label={option.text}
// //           />
// //         ))}
// //       </RadioGroup>

// //       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
// //         {question.difficulty && <Chip label={question.difficulty} variant="outlined" />}
// //         <Typography variant="caption">Time left: {timer}s</Typography>
// //       </Box>

// //       <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
// //         <Button variant="contained" onClick={handlePreviousQuestion} disabled={questionIndex === 0}>
// //           Previous
// //         </Button>
// //         {questionIndex === totalQuestions - 1 ? (
// //           <Button variant="contained" color="success" onClick={handleSubmitTest}>
// //             Submit
// //           </Button>
// //         ) : (
// //           <Button variant="contained" onClick={handleNextQuestion}>
// //             Next
// //           </Button>
// //         )}
// //       </Box>
// //     </Box>
// //   );
// // };

// // export default TestMCQCard;
// import React from 'react';
// import { Box, Typography, Avatar, Chip, Collapse, Button, Radio, FormControlLabel } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import SchoolIcon from '@mui/icons-material/School';

// const StyledCardForMCQ = styled(Box)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   borderRadius: '12px',
//   boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//   backgroundColor: '#ffffff',
//   padding: theme.spacing(3),
//   width: '100%',
// }));

// const TestMCQCard = ({
//   question,
//   questionIndex,
//   totalQuestions,
//   answers,
//   handleAnswerChange,
//   isSubmitted,
//   isCorrect,
//   timer,
//   handlePreviousQuestion,
//   handleNextQuestion,
//   handleSubmitTest,
// }) => {
//   return (
//     <Box sx={{ width: '100%', minHeight: '100vh', padding: '20px', backgroundColor: '#f5f5f5' }}>
//       {/* Header */}
//       <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
//         <Avatar sx={{ bgcolor: '#3498db', mr: 2 }}>
//           <SchoolIcon />
//         </Avatar>
//         <Typography variant="h5" sx={{ color: '#3498db' }}>
//           Question {questionIndex + 1} of {totalQuestions}
//         </Typography>
//         <Typography variant="h6" sx={{ marginLeft: 'auto', color: timer <= 10 ? 'error.main' : 'text.primary' }}>
//           Time Left: {timer}s
//         </Typography>
//       </Box>

//       {/* Question Box */}
//       <StyledCardForMCQ>
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
//           <Typography variant="subtitle1" sx={{ color: '#3498db' }}>
//             Question {questionIndex + 1}
//           </Typography>
//           <Chip label={question.difficulty || 'N/A'} color="primary" variant="outlined" />
//         </Box>
//         <Typography variant="body1" sx={{ color: '#34495e', mb: 2 }}>
//           {question.text}
//         </Typography>
//         <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
//           {question.options.map((option) => (
//             <FormControlLabel
//               key={option.id}
//               control={
//                 <Radio
//                   checked={answers[question.id]?.includes(option.id)} // Check if the option is selected
//                   onChange={() => handleAnswerChange(question.id, option.id)} // Handle answer change
//                   disabled={isSubmitted} // Disable when submitted
//                 />
//               }
//               label={<Typography sx={{ color: '#2c3e50' }}>{option.text}</Typography>}
//             />
//           ))}
//         </Box>
//         <Collapse in={isSubmitted}>
//           <Box sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: '8px' }}>
//             <Typography variant="body2" color={isCorrect ? 'success.main' : 'error.main'}>
//               {isCorrect ? 'Correct!' : 'Incorrect'}
//             </Typography>
//             <Typography variant="body2">
//               Correct Answer: {question.correctAnswers.join(', ')}
//             </Typography>
//             <Typography variant="body2" sx={{ mt: 1 }}>
//               {question.explanation || 'No explanation available.'}
//             </Typography>
//           </Box>
//         </Collapse>
//       </StyledCardForMCQ>

//       {/* Navigation Buttons */}
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           alignItems: 'center',
//           width: '100%',
//           maxWidth: '600px',
//           margin: '20px auto',
//         }}
//       >
//         <Button
//           onClick={handlePreviousQuestion}
//           disabled={questionIndex === 0}
//           variant="outlined"
//           sx={{ textTransform: 'none' }}
//         >
//           Previous
//         </Button>
//         {questionIndex === totalQuestions - 1 ? (
//           <Button
//             variant="contained"
//             sx={{
//               backgroundColor: '#3498db',
//               '&:hover': { backgroundColor: '#2980b9' },
//             }}
//             onClick={handleSubmitTest}
//           >
//             Submit Test
//           </Button>
//         ) : (
//           <Button
//             onClick={handleNextQuestion}
//             variant="outlined"
//             sx={{ textTransform: 'none' }}
//           >
//             Next
//           </Button>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default TestMCQCard;

import React from 'react';
import { Box, Typography, Avatar, Chip, Collapse, Button, Radio, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';

const StyledCardForMCQ = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffff',
  padding: theme.spacing(3),
  width: '100%',
}));

const TestMCQCard = ({
  question,
  questionIndex,
  totalQuestions,
  answers,
  handleAnswerChange,
  isSubmitted,
  isCorrect,
  timer,
  handlePreviousQuestion,
  handleNextQuestion,
  handleSubmitTest,
}) => {
  return (
    <Box sx={{ width: '100%', minHeight: '100vh', padding: '20px', backgroundColor: '#f5f5f5' }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Avatar sx={{ bgcolor: '#3498db', mr: 2 }}>
          <SchoolIcon />
        </Avatar>
        <Typography variant="h5" sx={{ color: '#3498db' }}>
          Question {questionIndex + 1} of {totalQuestions}
        </Typography>
        <Typography variant="h6" sx={{ marginLeft: 'auto', color: timer <= 10 ? 'error.main' : 'text.primary' }}>
          Time Left: {timer}s
        </Typography>
      </Box>

      {/* Question Box */}
      <StyledCardForMCQ>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="subtitle1" sx={{ color: '#3498db' }}>
            Question {questionIndex + 1}
          </Typography>
          <Chip label={question.difficulty || 'N/A'} color="primary" variant="outlined" />
        </Box>
        <Typography variant="body1" sx={{ color: '#34495e', mb: 2 }}>
          {question.text}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
          {question.options.map((option) => (
            <FormControlLabel
              key={option.id}
              control={
                <Radio
                  checked={answers[question.id]?.[0] === option.id} // Match the selected answer with the option ID
                  onChange={() => handleAnswerChange(question.id, option.id)} // Handle answer change
                  disabled={isSubmitted} // Disable when submitted
                />
              }
              label={<Typography sx={{ color: '#2c3e50' }}>{option.text}</Typography>}
            />
          ))}
        </Box>
        <Collapse in={isSubmitted}>
          <Box sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: '8px' }}>
            <Typography variant="body2" color={isCorrect ? 'success.main' : 'error.main'}>
              {isCorrect ? 'Correct!' : 'Incorrect'}
            </Typography>
            <Typography variant="body2">
              Correct Answer: {question.correctAnswers.join(', ')}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {question.explanation || 'No explanation available.'}
            </Typography>
          </Box>
        </Collapse>
      </StyledCardForMCQ>

      {/* Navigation Buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          maxWidth: '600px',
          margin: '20px auto',
        }}
      >
        <Button
          onClick={handlePreviousQuestion}
          disabled={questionIndex === 0}
          variant="outlined"
          sx={{ textTransform: 'none' }}
        >
          Previous
        </Button>
        {questionIndex === totalQuestions - 1 ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#3498db',
              '&:hover': { backgroundColor: '#2980b9' },
            }}
            onClick={handleSubmitTest}
          >
            Submit Test
          </Button>
        ) : (
          <Button
            onClick={handleNextQuestion}
            variant="outlined"
            sx={{ textTransform: 'none' }}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default TestMCQCard;
