// import React, { useState, useEffect } from 'react';
// import { Box, CircularProgress } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import TestMCQCard from './TestMCQCard'; // Import the TestMCQCard component

// const TestComponent = ({ subjectId }) => {
//   const navigate = useNavigate();
//   const [testDetails, setTestDetails] = useState(null);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [answers, setAnswers] = useState({});
//   const [submitted, setSubmitted] = useState(false);
//   const [timer, setTimer] = useState(60); // Timer for each question

//   useEffect(() => {
//     const fetchTestDetails = async () => {
//       try {
//         const response = await axios.get(`http://localhost:2004/api/test/67558bd4b82b95fc6d3aa682`);
//         const data = response.data;

//         // Map API response to the expected format
//         const formattedTestDetails = {
//           id: data.testId,
//           topic: data.metadata.topicsCovered.join(', '), // Combine topics
//           questions: data.questions.map((question) => ({
//             id: question._id,
//             text: question.text,
//             options: question.options.map((option) => ({
//               id: option.id,
//               text: option.text,
//             })),
//             correctAnswers: question.correctAnswers,
//             explanation: question.explanation,
//             type: question.questionType,
//             difficulty: question.difficulty,
//           })),
//         };

//         setTestDetails(formattedTestDetails);
//       } catch (error) {
//         console.error('Error fetching test details:', error.message);
//       }
//     };

//     fetchTestDetails();
//   }, [subjectId]);

//   useEffect(() => {
//     if (timer > 0) {
//       const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
//       return () => clearInterval(interval);
//     }

//     if (timer === 0 && currentQuestionIndex < testDetails.questions.length - 1) {
//       handleNextQuestion(); // Automatically move to the next question
//     }
//   }, [timer, currentQuestionIndex, testDetails]);

//   const handleAnswerChange = (questionId, option) => {
//     setAnswers((prev) => ({
//       ...prev,
//       [questionId]: option,
//     }));
//   };

//   const handleNextQuestion = () => {
//     if (currentQuestionIndex < testDetails.questions.length - 1) {
//       setCurrentQuestionIndex((prev) => prev + 1);
//       setTimer(60); // Reset timer
//     }
//   };

//   const handlePreviousQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex((prev) => prev - 1);
//       setTimer(60); // Reset timer
//     }
//   };

//   const handleSubmitTest = () => {
//     setSubmitted(true);
//     navigate('/results', { state: { testDetails, answers } });
//   };

//   if (!testDetails) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//         }}
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   const currentQuestion = testDetails.questions[currentQuestionIndex];
//   const isCorrect = currentQuestion.correctAnswers.includes(answers[currentQuestion.id]);

//   return (
//     <Box sx={{ padding: '20px' }}>
//       {/* <TestMCQCard
//         question={currentQuestion}
//         questionIndex={currentQuestionIndex}
//         totalQuestions={testDetails.questions.length}
//         answers={answers}
//         handleAnswerChange={handleAnswerChange}
//         isSubmitted={submitted}
//         isCorrect={isCorrect}
//         timer={timer}
//         handlePreviousQuestion={handlePreviousQuestion}
//         handleNextQuestion={handleNextQuestion}
//         handleSubmitTest={handleSubmitTest}
//       /> */}
//       <TestMCQCard
//   question={currentQuestion}
//   questionIndex={currentQuestionIndex}
//   totalQuestions={testDetails.questions.length}
//   answers={answers}
//   handleAnswerChange={handleAnswerChange}
//   isSubmitted={submitted}
//   isCorrect={isCorrect}
//   timer={timer}
//   handlePreviousQuestion={handlePreviousQuestion}
//   handleNextQuestion={handleNextQuestion}
//   handleSubmitTest={handleSubmitTest}
// />

// //     </Box>
//   );
// };

// export default TestComponent;
// import React, { useState, useEffect } from 'react';
// import { Box, CircularProgress, Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import TestMCQCard from './TestMCQCard'; // Import the TestMCQCard component

// const TestComponent = ({ subjectId }) => {
//   const navigate = useNavigate();
//   const [testDetails, setTestDetails] = useState(null);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [answers, setAnswers] = useState({});
//   const [submitted, setSubmitted] = useState(false);
//   const [timer, setTimer] = useState(60); // Timer for each question

//   useEffect(() => {
//     const fetchTestDetails = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:2004/api/test/${subjectId}`
//         );
//         const data = response.data;

//         // Format test details
//         const formattedTestDetails = {
//           id: data.testId,
//           topic: data.metadata.topicsCovered.join(', '), // Combine topics
//           questions: data.questions.map((question) => ({
//             id: question.questionId,
//             text: question.text,
//             options: question.options.map((option) => ({
//               id: option.id,
//               text: option.text,
//             })),
//             correctAnswers: question.correctAnswers,
//             explanation: question.explanation,
//             type: question.questionType,
//             difficulty: question.difficulty,
//           })),
//         };

//         setTestDetails(formattedTestDetails);
//       } catch (error) {
//         console.error('Error fetching test details:', error.message);
//       }
//     };

//     fetchTestDetails();
//   }, [subjectId]);

//   useEffect(() => {
//     if (timer > 0) {
//       const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
//       return () => clearInterval(interval);
//     }

//     if (timer === 0 && currentQuestionIndex < testDetails.questions.length - 1) {
//       handleNextQuestion(); // Automatically move to the next question
//     }
//   }, [timer, currentQuestionIndex, testDetails]);

//   const handleAnswerChange = (questionId, option) => {
//     setAnswers((prev) => ({
//       ...prev,
//       [questionId]: [option], // Assuming single-select answers for simplicity
//     }));
//   };

//   const handleNextQuestion = () => {
//     if (currentQuestionIndex < testDetails.questions.length - 1) {
//       setCurrentQuestionIndex((prev) => prev + 1);
//       setTimer(60); // Reset timer
//     }
//   };

//   const handlePreviousQuestion = () => {
//     if (currentQuestionIndex > 0) {
//       setCurrentQuestionIndex((prev) => prev - 1);
//       setTimer(60); // Reset timer
//     }
//   };

//   const handleSubmitTest = async () => {
//     try {
//       const payload = {
//         testId: testDetails.id,
//         answers: Object.keys(answers).map((questionId) => ({
//           questionId,
//           selectedOptions: answers[questionId],
//         })),
//       };

//       const response = await axios.post(
//         `http://localhost:2004/api/test/${testDetails.id}/submit`,
//         payload
//       );

//       setSubmitted(true);
//       navigate('/view-results', { state: response.data });
//     } catch (error) {
//       console.error('Error submitting test:', error.message);
//     }
//   };

//   if (!testDetails) {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100vh',
//         }}
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   const currentQuestion = testDetails.questions[currentQuestionIndex];
//   const isCorrect =
//     answers[currentQuestion.id]?.[0] === currentQuestion.correctAnswers?.[0];

//   return (
//     <Box sx={{ padding: '20px' }}>
//       <TestMCQCard
//         question={currentQuestion}
//         questionIndex={currentQuestionIndex}
//         totalQuestions={testDetails.questions.length}
//         answers={answers}
//         handleAnswerChange={handleAnswerChange}
//         isSubmitted={submitted}
//         isCorrect={isCorrect}
//         timer={timer}
//         handlePreviousQuestion={handlePreviousQuestion}
//         handleNextQuestion={handleNextQuestion}
//         handleSubmitTest={handleSubmitTest}
//       />
//       {currentQuestionIndex === testDetails.questions.length - 1 && !submitted && (
//         <Button
//           variant="contained"
//           sx={{ marginTop: '20px' }}
//           onClick={handleSubmitTest}
//         >
//           Submit Test
//         </Button>
//       )}
//     </Box>
//   );
// };

// export default TestComponent;


import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import TestMCQCard from './TestMCQCard'; // Import the TestMCQCard component


const API_BASE_URL = "https://neetlybackend.vercel.app/api";

const TestComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const subjectId = new URLSearchParams(location.search).get('subjectId');

  const [testDetails, setTestDetails] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    if (!subjectId) {
      console.error('subjectId is missing in the URL');
      return;
    }

    const fetchTestDetails = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/test/67558bd4b82b95fc6d3aa682`
        );
        const data = response.data;

        if (!data || !data.questions) {
          throw new Error('Invalid test data received');
        }

        const formattedTestDetails = {
          id: data.testId,
          topic: data.metadata.topicsCovered.join(', '),
          questions: data.questions.map((question) => ({
            id: question.questionId || question._id,
            text: question.text,
            options: question.options.map((option) => ({
              id: option.id,
              text: option.text,
            })),
            correctAnswers: question.correctAnswers,
            explanation: question.explanation,
            type: question.questionType,
            difficulty: question.difficulty,
          })),
        };

        setTestDetails(formattedTestDetails);
      } catch (error) {
        console.error('Error fetching test details:', error.message);
      }
    };

    fetchTestDetails();
  }, [subjectId]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    }

    if (timer === 0 && currentQuestionIndex < testDetails.questions.length - 1) {
      handleNextQuestion();
    }
  }, [timer, currentQuestionIndex, testDetails]);

  const handleAnswerChange = (questionId, option) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: [option],
    }));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < testDetails.questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setTimer(60);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      setTimer(60);
    }
  };

  const handleSubmitTest = async () => {
    try {
      const payload = {
        testId: testDetails.id,
        answers: Object.keys(answers).map((questionId) => ({
          questionId,
          selectedOptions: answers[questionId],
        })),
      };

      const response = await axios.post(
        `${API_BASE_URL}/test/${testDetails.id}/submit`,
        payload
      );

      setSubmitted(true);
      navigate('/view-test/67558bd4b82b95fc6d3aa682', { state: response.data });
    } catch (error) {
      console.error('Error submitting test:', error.message);
    }
  };

  if (!testDetails) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const currentQuestion = testDetails.questions[currentQuestionIndex];
  const isCorrect =
    answers[currentQuestion.id]?.[0] === currentQuestion.correctAnswers?.[0];

  return (
    <Box sx={{ padding: '20px' }}>
      <TestMCQCard
        question={currentQuestion}
        questionIndex={currentQuestionIndex}
        totalQuestions={testDetails.questions.length}
        answers={answers}
        selectedAnswer={answers[currentQuestion.id]} // Pass selected answer
        handleAnswerChange={handleAnswerChange}
        isSubmitted={submitted}
        isCorrect={isCorrect}
        timer={timer}
        handlePreviousQuestion={handlePreviousQuestion}
        handleNextQuestion={handleNextQuestion}
        handleSubmitTest={handleSubmitTest}
      />
      {/* {currentQuestionIndex === testDetails.questions.length - 1 && !submitted && (
        <Button
          variant="contained"
          sx={{ marginTop: '20px' }}
          onClick={handleSubmitTest}
        >
          Submit Test
        </Button>
      )} */}
    </Box>
  );
};

export default TestComponent;
